import React, { useRef, useContext, useEffect ,useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {Grid,ButtonBase, CircularProgress, Tooltip, styled, Button, colors} from "@mui/material";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import ScannerOutlinedIcon from '@mui/icons-material/ScannerOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import SpeakerOutlinedIcon from '@mui/icons-material/SpeakerOutlined';
import {  selectMic} from '../../redux/micSlice';
import { selectCamera } from '../../redux/cameraSlice';
import { selectSpeaker } from '../../redux/speaker';
import Storages from '../../constants/storages';
import MenuListComposition from '../Controls/MenuListComposition';
import FilterOutlinedIcon from '@mui/icons-material/FilterOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import CameraIcon from '@mui/icons-material/Camera';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import { toast } from "react-toastify";
import AdminService from '../../services/api'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LaunchIcon from '@mui/icons-material/Launch';
import './styleReception.css'

const ToolboxButton = styled(Button)(({
  theme: {palette, mode}
}) => ({
  marginInline: "4px",
  padding: "9px",
  backgroundColor: palette.color[mode],//"#4c5053",
  borderRadius: "24px",
  minWidth: "0px",
  '& svg': {
    fontSize: "1.7rem",
    color: palette.background[mode],
  },
  ':hover': {
    backgroundColor: palette.toolboxButtonHover[mode],
  },
}))

const Root = styled('div')(({
  theme: {palette, mode}
}) => ({
  '& .reception-end-call-button': {
    backgroundColor: palette.error[mode],
    color: palette.error.contrastText,
    ':hover': {
      backgroundColor: palette.error[mode] + "cc",
    },
  },

  '& .reception-start-call-button': {
    backgroundColor: palette.success[mode],
    color: palette.success.contrastText,
    ':hover': {
      backgroundColor: palette.success[mode] + "cc",
    },
  },

  '& .reception-hold-call': {
    backgroundColor: palette.warning[mode],
    color: palette.warning.contrastText,
    ':hover': {
      backgroundColor: palette.warning[mode] + "cc",
    },
  },

  '& .reception-release-call': {
    backgroundColor: palette.success[mode],
    color: palette.success.contrastText,
    ':hover': {
      backgroundColor: palette.success[mode] + "cc",
    },
  }
}))

const audioContext = new AudioContext();

export default function ToolBox ({callState, handleCall, locationEngaged, allLocations, handlePlay,
    playState,handleOpenModalPayReq,handleRequestForScan,handleModalPrintFile,
    handleLocalCamera, handleLocalMic, micro, camera,handleOpenGallery, startCardDetection,
    toggleShareScreen, captureLocationCameraImage, handleExitFullScreenEngaged,
    monitorCallStatus,handleOpenMessagingBoard, handleDeviceManagement, callLoading, 
}){
    const dispatch = useDispatch();
    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const customURLs = useSelector((state) => state.customURLs);
    const speakers=useSelector((state)=>state.speakers)
    const [showPayButton, setShowPayButton] = useState(true)
    const [audioInputDevicesMenu, setAudioInputDevicesMenu] = useState([]); // {selected, label,id}
    const [audioOutputDevicesMenu,setAudioOutputDevicesMenu]=useState([])
    const [showCardDetection, setShowCardDetection] = useState(true)
    const [showMoreButton, setShowMoreButton] = useState(false)
    const [mainBodyWidth, setMainBodyWidth] = useState(0);
    const [buttons, setButtons] = useState([
      {
        id: "gallery",
        label: "Open gallery",
        icon: <FilterOutlinedIcon className="toolbox-icon" />,
        onClick: handleOpenGallery,
        hidden: false,
      },
      {
        id: "payment",
        label: "Open payment",
        icon: <PaymentOutlinedIcon className="toolbox-icon" />,
        onClick: handleOpenModalPayReq,
        hidden: true,
      },
      {
        id: "print",
        label: "Print request",
        icon: <PrintOutlinedIcon className="toolbox-icon" />,
        onClick: handleModalPrintFile,
        hidden: true,
      },
      {
        id: "scan",
        label: "Scan request",
        icon: <ScannerOutlinedIcon className="toolbox-icon" />,
        onClick: handleRequestForScan,
        hidden: true,
      },
      {
        id: "boards",
        label: "Messaging Board",
        icon: <LibraryBooksIcon className="toolbox-icon" />,
        onClick: handleOpenMessagingBoard,
        hidden: true,
      },
      {
        id: "idCard",
        label: "Detect ID card",
        icon: <PageviewOutlinedIcon className="toolbox-icon" />,
        onClick: startCardDetection,
        hidden: true,
      },
      {
        id: "picture",
        label: "Take picture",
        icon: <CameraIcon className="toolbox-icon" />,
        onClick: captureLocationCameraImage,
        hidden: true,
      },
      {
        id: "screenshot",
        label: "Start / Stop share screen",
        icon: <ScreenShareOutlinedIcon className="toolbox-icon" />,
        onClick: toggleShareScreen,
        hidden: true,
      },
      {
        id: "device",
        label: "Device management",
        icon: <GraphicEqIcon className="toolbox-icon" />,
        onClick: handleDeviceManagement,
        hidden: true,
      },
      // { id: "url", label: "Open URL", icon: <LaunchIcon/>, onClick:  },
    ]);
    const micNodes = useRef([]);
    const notAllowedAlertShown = useRef(false);
    const debounceTimeoutRef = useRef(null);

    useEffect(() => {
      window.requestAnimationFrame(checkAudioLevelForAllMicNodes)

      const mainBody = document.querySelector('#mainBodyLocationArea');
      const resizeObserver = new ResizeObserver(() => {
        clearTimeout(debounceTimeoutRef.current)
        debounceTimeoutRef.current = setTimeout(async () => {
          setMainBodyWidth(mainBody.clientWidth)
        }, 200);
      });
      
      resizeObserver.observe(mainBody);
      return(() => {
        resizeObserver.disconnect()
      })
    }, [])

    useEffect(() => {
      getAllMicsAnalyserNodes()
    }, [mics])

    useEffect(()=>{
        if(speakers[0]){
            let arr=speakers.map((s)=>({...s,isSpeaker:true}))
            setAudioOutputDevicesMenu(arr)
        }
    },[speakers])

    const getAllMicsAnalyserNodes = async () => {
      try{
        const tmpMiceNodes = [];
        for(let mic of mics) {  
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: {
                exact: mic.deviceId,
              },
            },
          });

          const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
          const analyserNode = audioContext.createAnalyser();
          mediaStreamAudioSourceNode.connect(analyserNode);
          
          const array = new Uint8Array(analyserNode.frequencyBinCount);

          tmpMiceNodes.push({analyserNode, pcmData: array, mic})
        }
        micNodes.current = tmpMiceNodes;
      } catch(err) {
        if(!notAllowedAlertShown.current) {
          console.error(2020,err)
          err.message && toast.error("Get microphone levels: " + err.message)
          notAllowedAlertShown.current = true
        }
      }
    }

    const checkAudioLevelForAllMicNodes = async () => {
      try{
        let tmpAudioDevicesMenu = [];
        for(let micNode of micNodes.current) { 
          micNode.analyserNode.getByteTimeDomainData(micNode.pcmData);
          let level = timeDomainDataToAudioLevel(micNode.pcmData)

          // let label = micNode.mic.label
          // if(label.length > 30) {
          //   label = label.substr(0, 30) + "..."
          // }
          tmpAudioDevicesMenu.push({id:micNode.mic.id, label: micNode.mic.label, selected: micNode.mic.selected, bar: level*100})
        }
        setAudioInputDevicesMenu(tmpAudioDevicesMenu)

        window.requestAnimationFrame(checkAudioLevelForAllMicNodes);
      } catch (err) {
        console.error(2022,err)
      }
    }

    const timeDomainDataToAudioLevel = (samples) => {
      let maxVolume = 0;
      const length = samples.length;
      for (let i = 0; i < length; i++) {
        if (maxVolume < samples[i]) {
            maxVolume = samples[i];
        }
      }
      return parseFloat(((maxVolume - 127) / 128).toFixed(3));
    }

    useEffect(() => {
      if (!locationEngaged) {
        setShowPayButton(false)
      }
    }, [locationEngaged])

    useEffect(() => {
      if(locationEngaged) {
        const engagedObj = allLocations.find((item) => item.username === locationEngaged);
        if (engagedObj && engagedObj.deviceId) {
          setShowPayButton(true)
        } else {
          setShowPayButton(false)
        }
        if(engagedObj?.cardDetectionEnabled) {
          setShowCardDetection(true)
        } else {
          setShowCardDetection(false)
        }
      }
    }, [locationEngaged, allLocations])

    useEffect(() => {
      if(locationEngaged && mainBodyWidth < 800 || callState && mainBodyWidth < 950) {
        setShowMoreButton(true)
      } else {
        setShowMoreButton(false)
      }
    }, [mainBodyWidth, locationEngaged, callState])

    const onClickCameraSelector = (index) => {
        // let cameraDeviceId=cameras[index].deviceId
        // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
        dispatch(selectCamera({ index }));
        let label=cameras[index]?.label
        AdminService.createActivityLog({
          tags:['reception', 'camera'],
          action: "update-device",
          subAction: "camera",
          target: "__ME__",
          details: "Reception set camera to " + label
        })
    }
    const onClickMicSelector = (index) => {
        // localStorage.setItem(Storages.LOCAL_MIC_ID,deviceId)
        dispatch(selectMic({ index }));
        let label=mics[index]?.label
        AdminService.createActivityLog({
          tags: ['reception', 'microphone'],
          action: "update-device",
          subAction: "microphone",
          target: "__ME__",
          details: `Reception set microphone to ${label}`
        })
    }
    const onClickSpeakerSelector=async(index)=>{
        try {
            let speakerDeviceId = speakers[index].deviceId;
            localStorage.setItem(Storages.LOCAL_SPEAKER_ID,speakerDeviceId)
            dispatch(selectSpeaker({ deviceId: speakerDeviceId }));
            if(speakerDeviceId){
                document.getElementById('locationAudio') && (await document.getElementById('locationAudio').setSinkId(speakerDeviceId))
                document.getElementById('mainRAudio') && (await document.getElementById('mainRAudio').setSinkId(speakerDeviceId))
                document.getElementById('alarmAudio') && (await document.getElementById('alarmAudio').setSinkId(speakerDeviceId)) 
            }
            let label=speakers[index]?.label
            AdminService.createActivityLog({
              tags: ['reception', 'speaker'],
              action: "update-device",
              subAction: "speaker",
              target: "__ME__",
              details: `Reception set speaker to ${label}`
            })
        } catch (error) {
            console.error("12364: ",error)
        }
    }

    const onClickCustomURLSelector=async(id)=>{
      try {
        if(id=='close-url'){
          await AdminService.sendMessage({
            to: locationEngaged,
            event: "close-url",
          })
          return
        }
        const url=customURLs.find(u=>u.id==id)
        if(!url) return
        await AdminService.sendMessage({
          to: locationEngaged,
          event: "launch-url",
          msg:url
        })
      } catch (error) {
        console.error(1340, error)
      }
    }

    const buttonClicked = (id) => {
      buttons.find(button => button.id == id).onClick()
    }

    useEffect(() => {
      let tmpButtons = [...buttons]
      for(let button of tmpButtons) {
        button.hidden = false;
      }

      if(!locationEngaged) {
        for(let button of tmpButtons) {
          if(button.id !== "gallery") {
            button.hidden = true;
          }
        }
      }
      if(!showPayButton) {
        tmpButtons.find(button => button.id === "payment").hidden = true
      } 
      if(!showCardDetection) {
        tmpButtons.find(button => button.id === "idCard").hidden = true
      }
      if(!callState) {
        for(let button of tmpButtons) {
          if(button.id == "payment" || button.id == "print") {
            button.hidden = true;
          }
        }
      }

      setButtons(tmpButtons)
    }, [showPayButton, callState, locationEngaged, showCardDetection, customURLs])
    
    return (
        <Root> 
            <Grid container direction='row-reverse'>
                { callState ?
                  <>
                    <ToolboxButton className="reception-end-call-button" onClick={handleCall}>
                      <Tooltip title='End Call'>
                        <CallEndOutlinedIcon className='toolbox-icon' />
                      </Tooltip>
                    </ToolboxButton>
                    <ToolboxButton className={playState ? "reception-hold-call" : "reception-release-call"} 
                      style={{paddingLeft: "8px"}} onClick={handlePlay}>
                      <Tooltip title={playState?"Hold Call":"Release Call"}>
                          {playState
                            ? <PhonePausedOutlinedIcon className="toolbox-icon" />
                            : <PhoneInTalkOutlinedIcon className="toolbox-icon" />
                          }
                      </Tooltip>
                    </ToolboxButton>
                  </>
                : locationEngaged &&
                  <>
                    <ToolboxButton className="reception-start-call-button" onClick={handleCall} disabled={callLoading}>
                      <Tooltip title={callLoading?'Connecting...':'Call'}>
                        {callLoading ?
                        <CircularProgress size={25} className='toolbox-icon' />:
                        <CallOutlinedIcon className='toolbox-icon' /> }
                      </Tooltip>
                    </ToolboxButton>
                  </>
                }
                {((locationEngaged && (!callState || (callState && !playState))) || monitorCallStatus) ? 
                    <ToolboxButton onClick={handleExitFullScreenEngaged}>
                      <Tooltip title='Exit Full Screen'>
                        <FullscreenExitOutlinedIcon className='toolbox-icon' />
                      </Tooltip> 
                    </ToolboxButton>
                  : ""
                }
                {showMoreButton ? 
                  <span style={{marginInline: "4px"}}>
                    <MenuListComposition 
                      onClickItemSelector={(id) => buttonClicked(id)} 
                      menus={buttons.filter(button => {
                        if(!button.hidden){
                          return {
                            id: button.id,
                            label: 
                              <label className='mb-0' style={{cursor: "pointer"}}>
                                <span className="mr-3">{button.icon}</span> 
                                {button.label}
                              </label>
                          }
                        }
                      })} 
                      title="More options"
                      // child={
                      //   <ButtonBase className='mr-2' onClick={handleLocalCamera}>
                      //     <MenuIcon className='toolbox-icon'/>
                      //   </ButtonBase>
                      // }
                    />
                  </span> :
                  <>
                    {buttons.map(button => (
                      !button.hidden && 
                        <ToolboxButton key={button.id} onClick={button.onClick}>
                          <Tooltip title={button.label}>
                            {button.icon}
                          </Tooltip>
                        </ToolboxButton>
                    ))}
{/* 
                   {showPayButton && callState &&
                     <ToolboxButton onClick={handleOpenModalPayReq}>
                       <Tooltip title='Open payment'>
                         <PaymentOutlinedIcon className='toolbox-icon'/>
                       </Tooltip>
                     </ToolboxButton>
                   }
                   {locationEngaged && callState &&
                     <ToolboxButton onClick={handleModalPrintFile}>
                       <Tooltip title='Print request'>
                         <PrintOutlinedIcon className='toolbox-icon'/>
                       </Tooltip>
                     </ToolboxButton>
                   }
                   {locationEngaged && <>
                     <ToolboxButton onClick={handleRequestForScan}>
                       <Tooltip title='Scan request'>
                         <ScannerOutlinedIcon className='toolbox-icon'/>
                       </Tooltip>
                     </ToolboxButton>
                     <ToolboxButton onClick={handleOpenMessagingBoard}>
                       <Tooltip title='Messaging Board'>
                         <LibraryBooksIcon className='toolbox-icon'/>
                       </Tooltip>
                     </ToolboxButton>
                     {showCardDetection && 
                       <ToolboxButton onClick={startCardDetection}>
                         <Tooltip title="Detect ID card in location's camera">
                           <PageviewOutlinedIcon className='toolbox-icon' />
                         </Tooltip>
                       </ToolboxButton>
                     }
                     <ToolboxButton onClick={captureLocationCameraImage}>
                       <Tooltip title="Take picture from location's camera">
                         <CameraIcon className='toolbox-icon' />
                       </Tooltip>
                     </ToolboxButton>
                     <ToolboxButton onClick={toggleShareScreen}>
                       <Tooltip title='Start / Stop location share screen'>
                         <ScreenShareOutlinedIcon className='toolbox-icon'/>
                       </Tooltip>
                     </ToolboxButton>
                     <ToolboxButton onClick={handleDeviceManagement}>
                       <Tooltip title={`Device management and volume`}>
                         <GraphicEqIcon className='toolbox-icon' />
                       </Tooltip>
                     </ToolboxButton>
                     { customURLs && customURLs[0]  && callState &&
                       <ToolboxButton>
                         <MenuListComposition 
                           onClickItemSelector={(index) => onClickCustomURLSelector(index)} 
                           menus={customURLs} 
                           title="Open URL on location" 
                           child={ <LaunchIcon className='toolbox-icon mr-2'/>}
                         />
                       </ToolboxButton>
                      } 
                   </>} */}

                </>}
                { customURLs && customURLs[0] && callState &&
                  <span style={{marginInline: "4px"}}>
                    <MenuListComposition 
                      onClickItemSelector={(index) => onClickCustomURLSelector(index)} 
                      menus={customURLs} 
                      title="Open URL on location" 
                      child={ 
                        <ButtonBase className='mr-2'>
                          <LaunchIcon className='toolbox-icon'/>
                        </ButtonBase>
                      }
                    />
                  </span>
                } 
                <span style={{marginInline: "4px"}}>
                  <MenuListComposition 
                    onClickItemSelector={(id) => onClickCameraSelector(id)} 
                    menus={cameras} 
                    title="Select camera"
                    child={
                      <ButtonBase className='mr-2' onClick={handleLocalCamera}>
                        {camera?<VideocamOutlinedIcon className='toolbox-icon'/>
                          :<VideocamOffOutlinedIcon className='toolbox-icon'/>
                        }
                      </ButtonBase>
                    }
                  />
                </span>
                <span style={{marginInline: "4px"}}>
                  <MenuListComposition 
                    style={{marginInline: "4px"}}
                    onClickItemSelector={(id) => onClickMicSelector(id)} 
                    title="Select microphone / speaker"
                    onClickItemSpeaker={(id)=>onClickSpeakerSelector(id)} 
                    child={
                      <ButtonBase className='mr-2' onClick={handleLocalMic}>
                        {micro? <MicNoneOutlinedIcon className='toolbox-icon'/>
                        :<MicOffOutlinedIcon className='toolbox-icon' /> }
                      </ButtonBase>
                    }
                    menus={[
                      {
                        disabled:true,
                        label:<label className='mb-0'> <MicNoneOutlinedIcon/>Microphones</label>
                      },
                      ...audioInputDevicesMenu,
                      {
                        disabled:true,
                        label:<label className='mb-0'><SpeakerOutlinedIcon/>Speakers</label>
                      },
                      ...audioOutputDevicesMenu
                    ]}
                  />
                </span>
            </Grid>
        </Root>
    );
}

